{
	/******************************************************************************************
	"READ MORE" BUTTONS
	******************************************************************************************/
	/*
	Wrap a div around anything you want a read more button added to, eg:

	Basic read more where all content is hidden:
	<div class="js-read-more" data-btn-text="Read more|Read less"> ... content ... </div>

	Fancy read more where a certain height of content is shown, and ellipses are added if necessary
	<div class="js-read-more" data-height="6rem" data-btn-text="Read more|Read less"> ... content ... </div>
	
	Can also add a target to scroll back to when content is closed (defaults to the read-more element itself)
	<div class="js-read-more" data-height="6rem" data-btn-text="Read more|Read less" data-return-to="some-parent-element"> ... content ... </div>
	*/

	/******************************************************************************************
	Config (shouldn't need to edit anything below this)
	*******************************************************************************************/

	var siteHeaderClass = '.site-header--is-fixed'; // used to calculate scroll position when scrolling to top of elements
	
	/******************************************************************************************
	Helper functions
	*******************************************************************************************/

	function autoHeightAnimate(element, time){
		var curHeight = element.height(), // Get Default Height
			autoHeight = element.css('height', 'auto').height(); // Get Auto Height
		element.height(curHeight); // Reset to Default Height
		element.stop().animate({ height: autoHeight }, parseInt(time), function() {
			element.height('');
		}); // Animate to Auto Height
	}

	function scrollToElement(element) {
		// this function scrolls to the top of the element passed to it, adding extra space for nav if needed
		target_y = element.offset().top;
		if ($(siteHeaderClass).length) {
			target_y -= $(siteHeaderClass).outerHeight();
		}

		// if we're already higher on page than where we're scrolling to, cancel
		if (target_y > $('body').scrollTop()) return false;
		
		// do the scroll
		$('body').addClass('js-auto-scrolling');
		$('html, body').animate({scrollTop: target_y}, 400);
		// need to use queue rather than adding to animate's function, as otherwise class gets removed just as last bit of scrolling happens
		$('body').delay(500).queue(function(next) {
			$(this).removeClass('js-auto-scrolling');
			next();
		});
	}

	$('.js-read-more').each(function() {

		/******************************************************************************************
		Variables
		*******************************************************************************************/

		var target = $(this),
			readMoreHeight = target.attr('data-height'),
			truncateText = (typeof readMoreHeight !== typeof undefined && readMoreHeight !== false) ? true : false,
			readMoreButtonTexts = target.attr('data-btn-text').split('|'),
			readMoreBtnTextDefault = readMoreButtonTexts[0],
			readMoreBtnTextOpen = readMoreButtonTexts[1],
			readMoreBtnClass = target.attr('data-btn-class'),
			disableFrom = target.attr('data-disable-from'),
			isDisabled = true,
			isOpen = false;
		
		// if read more button text wasn't specified, set default
		if (typeof readMoreBtnClass == typeof undefined || readMoreBtnClass == false) {
			readMoreBtnClass = 'read-more-btn btn';
		}

		// if disableFrom was set in HTML, convert to int
		if (typeof disableFrom !== typeof undefined && disableFrom !== false) {
			disableFrom = parseInt(disableFrom)
		// otherwise set it to a v high number so it's never disabled
		} else {
			disableFrom = 9999999;
		}

		/******************************************************************************************
		Functions
		*******************************************************************************************/

		// add our Read More button (hidden until we enable show/hide)
		var readMoreBtn = $('<button class="' + readMoreBtnClass + '">' + readMoreBtnTextDefault + '</button>');
		readMoreBtn.hide().insertAfter(target);
		
		function disable() {
			isDisabled = true;
			// remove any dotdotdot plugin, show full text, hide btn
			target.trigger('destroy');
			target.show().css('height', 'auto');
			readMoreBtn.hide();
		}

		function enable() {
			isDisabled = false;
			// if panel isn't open
			if (!isOpen) {
				// if not truncating text, hide it
				if (!truncateText) {
					target.hide();
				// if truncting text...
				} else {
					// truncate
					target.height(readMoreHeight).dotdotdot();
					// if content was already shorter than container height, disable show/hide
					if (!target.triggerHandler('isTruncated')) {
						disable();
						return false;
					}
				}
			}
			// show button
			readMoreBtn.show();
		}

		function update() {
			// if this text is down for truncating, and it's not already open, redo truncating
			if (truncateText && !isOpen) {
			 	target.trigger('update.dot');
				// if content is already shorter than container height, disable show/hide
				if (!target.triggerHandler('isTruncated')) {
					disable();
				}
			 }
		}

		// Toggle open/closed.
		// Note: if button was clicked, we know "read more" isn't currently disabled, so fine to do all of this!
		readMoreBtn.click(function() {

			// toggle button state and isOpen variable
			readMoreBtn.toggleClass('js-is-active');
			isOpen = !isOpen;

			// if we're opening target ...
			if (isOpen) {
				// update btn text
				readMoreBtn.text(readMoreBtnTextOpen);
				// if we're truncating text, remove dotdotdot plugin and animate target to full height
				if (truncateText) {
					target.trigger('destroy');
					autoHeightAnimate(target, 400);
				// otherwise, just slide target down 
				} else {
					target.slideDown();
				}
			// if we're closing target ...
			} else {
				// update btn text
				readMoreBtn.text(readMoreBtnTextDefault);
				// if we're truncating text, animate target to correct height and add dotdotdot plugin
				if (truncateText) {
					target.animate({
						height: readMoreHeight
					}, 400, function() {
						target.dotdotdot();
					})
				// otherwise, just slide target up 
				} else {
					target.slideUp();					
				}
				// finally, scroll to top of target, or top of element specified in HTML attribute
				if (target.attr('data-return-to')) {
					scrollToElement($('.' + target.attr('data-return-to')));
				} else {
					scrollToElement(target);
				}
			}
		});

		// reinitialise whenever window is resized/loaded
		$(window).on('load resize', function() {
			// if our screen is wider than the "disable from" setting, disable show/hide
			if (Modernizr.mq('only all and (min-width: ' + disableFrom + 'px)')) {
				if (!isDisabled) {
					disable();
				}
			// otherwise enable it, or update it (if it's already enabled)
			} else {
				if (isDisabled) {
					enable();
				} else {
					update();
				}
			}
		});
	})
}