//=require third-party/jquery.min.js
//=require third-party/modernizr.js
//=require third-party/owl.carousel.js
//=require third-party/lazysizes.js
//=require third-party/underscore-throttle.js


$(document).ready(function () {

	/******************************************************************************************
	SCREEN SIZES
	******************************************************************************************/

	var navBreakpoint = 1170;

	function isDesktopNav() {
		return Modernizr.mq('only all and (min-width: ' + navBreakpoint + 'px)');
	}

	/******************************************************************************************
	LOCK/UNLOCK BODY FROM SCROLLING
	******************************************************************************************/

	function lockBody() {
		var body = $('body'),
			header = $('.site-header'),
			navPanels = $('.nav__panel'),
			bodyScrollPos = $(window).scrollTop(),
			bodyWidthBeforeNavOpen = $('body').width();

		body.css({
			'position': 'fixed',
			'top': -bodyScrollPos + 'px',
			'left': '0',
			'right': '0'
		});
		// if there was a scrollbar on page, add some padding of same width to body so whole page doesn't shift
		var scrollbarWidth = $('body').width() - bodyWidthBeforeNavOpen;
		// also add to header and nav panels so they don't shift around
		if (scrollbarWidth > 0) {
			body.add(header).add(navPanels).css({
				'paddingRight': scrollbarWidth + 'px'
			});
		}
	}
	function unlockBody() {
		var body = $('body'),
			header = $('.site-header'),
			navPanels = $('.nav__panel'),
			bodyScrollPos = -parseInt(body.css('top'));

		// if body isn't already locked, don't do anything
		if (body.css('position') != 'fixed') return;

		// reset the styles we added to body, header and nav panels when we locked the page
		body.css({
			'position': '',
			'top': '',
			'left': '',
			'right': '',
			'paddingRight': ''
		});
		header.add(navPanels).css({
			'paddingRight': ''
		});

		$(window).scrollTop(bodyScrollPos);

		console.log('unlocked body');
	}


	/******************************************************************************************
	STICKY NAV
	******************************************************************************************/

	var header = $('.js-site-header'),
		currentScrollPos = 0;

	$(window).on('scroll resize load', _.throttle(function() {

		// if we're auto-scrolling after closing a "read more" link or accordion, don't change header state
		if (navIsLocked) return false;

		// headerOffset is used when header doesn't start at top of viewport, eg WJ home page
		var headerOffest = 0,
			oldScrollPos = currentScrollPos,
			headerHeight = header.outerHeight();

		currentScrollPos = $(window).scrollTop() - headerOffest;

		// ----- CHECK IF IT WAS A PROPER SCROLL ----

		// If we've moved less than 5px in last 100ms, and we've not just very slowly crept to top of the page, don't do anything
		if (Math.abs(currentScrollPos - oldScrollPos) < 5 && currentScrollPos != 0) return false;

		// ----- APPLY SOME CLASSES ----

		// if we're at top of page (or before where header would go offscreen on WJ home page), show our normal, relative-positioned header
		if (currentScrollPos <= -headerOffest) {
			header.removeClass('site-header--is-fixed site-header--is-hidden');
		}
		// if we've just scrolled up but aren't yet at very top, show "fixed" header
		else if (currentScrollPos < oldScrollPos && currentScrollPos > -headerHeight) {
			header.removeClass('site-header--is-hidden').addClass('site-header--is-fixed');
		// if we're just scrolled down and are past point where we'd be able to see header, set header to "hidden"
		} else if (currentScrollPos >= oldScrollPos && currentScrollPos > headerHeight) {
			header.removeClass('site-header--is-fixed site-header--nav-is-open').addClass('site-header--is-hidden');
		// otherwise, show our normal, relative-positioned header
		} else {
			header.removeClass('site-header--is-fixed');
		}
	}, 100));

	/******************************************************************************************
	LOCK/UNLOCK/RESET NAV
	*******************************************************************************************/

	var navIsLocked = false;

	function lockNav() {
		navIsLocked = true;
		// console.log('nav locked');
	}
	function unlockNav() {
		// adds a bit of extra time to catch accidental scrolls, or timed functions overlapping
		setTimeout(function() {
			navIsLocked = false;
			// console.log('nav unlocked');
		}, 100);
	}
	function resetNav() {
		var navBtn = $('.site-header__nav-btn'),
			navBtnActiveClass = 'site-header__nav-btn--is-active',
			sectionLinks = $('.nav__section'),
			navPanels = $('.nav__panel'),
			sectionLinksActiveClass = 'nav__section--is-active';
		navBtn.removeClass(navBtnActiveClass);
		sectionLinks.removeClass(sectionLinksActiveClass);
		navPanels.hide();
		unlockNav();
		unlockBody();
	}

	/******************************************************************************************
	OPEN/CLOSE WHOLE NAV ON SMALLER SCREENS
	*******************************************************************************************/

	(function () {
		var navBtn = $('.site-header__nav-btn'),
			nav = $('.nav'),
			navBtnActiveClass = 'site-header__nav-btn--is-active';

		navBtn.click(function() {
			navBtn.toggleClass(navBtnActiveClass);
			if (navBtn.hasClass(navBtnActiveClass)) {
				nav.animate({
					marginLeft: '0',
					marginRight: '0',
				}, 300);
				lockNav();
				lockBody();
				nav.scrollTop(0); // reset scroll position of nav when we open it
			} else {
				nav.animate({
					marginLeft: '100%',
					marginRight: '-100%',
				}, 300);
				unlockNav();
				unlockBody();
			}
		});
	})();

	/******************************************************************************************
	HIDE/SHOW NAV PANELS
	******************************************************************************************/

	(function () {
		var sectionLinks = $('.nav__section').not('.nav__section--contact'),
			navPanels = $('.nav__panel'),
			sectionLinksActiveClass = 'nav__section--is-active',
			navPanelSpeed = 300;

		sectionLinks.click(function(e) {
			e.stopPropagation();
			var clickedSectionLink = $(this),
				desktopNav = Modernizr.mq('only all and (min-width: ' + navBreakpoint + 'px)');

			navPanels.scrollTop(0); // reset scroll position of nav panels

			// if we're on desktop nav and we've already got another nav panel open, do fancy resizing
			if (desktopNav && !clickedSectionLink.hasClass(sectionLinksActiveClass) && $('.' + sectionLinksActiveClass).length) {
				// identify which panel's opening and which is closing
				var newPanel = clickedSectionLink.next(navPanels),
					oldPanel = navPanels.filter(':visible');
				// unhighlight other nav items
				sectionLinks.removeClass(sectionLinksActiveClass);
				// highlight one we've just clicked on
				clickedSectionLink.toggleClass(sectionLinksActiveClass);
				newPanel.show();
				oldPanel.hide();
			// otherwise we can just slide toggle our panel
			} else {
				// open nav panel
				if (!clickedSectionLink.hasClass(sectionLinksActiveClass)) {
					clickedSectionLink.addClass(sectionLinksActiveClass).next(navPanels).slideDown(navPanelSpeed);
					sectionLinks.not(clickedSectionLink).removeClass(sectionLinksActiveClass).next(navPanels).slideUp(navPanelSpeed);
					if (isDesktopNav()) {
						lockNav();
						lockBody();
					}
				// close nav panel
				} else {
					if (isDesktopNav()) {
						// on desktop version we remove class only when panel has finished sliding up, so little triangle stays til the end
						clickedSectionLink.next(navPanels).slideUp(navPanelSpeed, function() {
							clickedSectionLink.removeClass(sectionLinksActiveClass);
						});
						unlockNav();
						unlockBody();
					} else {
						clickedSectionLink.removeClass(sectionLinksActiveClass).next(navPanels).slideUp(navPanelSpeed);
					}
				}
			}
		});
		// stop clicks on whole nav panel bubbling down to body and triggering close
		navPanels.click(function(e) {
			e.stopPropagation();
		});
	})();

	/******************************************************************************************
	RESET NAV/BODY LOCK WHEN SWITCHING TO DESKTOP
	******************************************************************************************/

	(function () {
		var wasDesktopNav = isDesktopNav();
		$(window).on('resize', _.throttle(function() {
			if (isDesktopNav() != wasDesktopNav) {
				wasDesktopNav = isDesktopNav();
				resetNav();
			}
		}, 100));
	})();

	/******************************************************************************************
	HIDE/SHOW SEARCH ON DESKTOP
	******************************************************************************************/

	(function () {
		var searchBtn = $('.nav__search-btn'),
			searchInput = $('.nav__search'),
			searchInputActiveClass = 'nav__search--is-open',
			searchSvg = '<path fill="none" stroke="#253d7d" stroke-width="2" d="M12.1 12.1l7.2 7.2"/><circle cx="7.8" cy="7.8" r="6.8" fill="none" stroke="#253d7d" stroke-width="2"/>',
			closeSvg = '<path class="close" fill="none" stroke="#253d7d" stroke-width="2" d="M.7.7l18.6 18.6m0-18.6L.7 19.3"/>';
		searchBtn.click(function(e) {
			e.stopPropagation();
			searchInput.toggleClass(searchInputActiveClass);
			searchBtn.html(searchInput.hasClass(searchInputActiveClass) ? closeSvg : searchSvg);
			if (searchInput.hasClass(searchInputActiveClass)) {
				setTimeout(function() {
					$('.nav__search__input').focus();
				}, 100);
				resetNav();
			}
		});
		searchInput.click(function(e) {
			e.stopPropagation();
		});
	})();

	/******************************************************************************************
	CLOSE PANELS (NAV, SEARCH, ANYTHING ELSE) ON BODY CLICK/ESC KEY
	If adding more panels here, remember to also do e.stopPropogation() for clicks on them!
	******************************************************************************************/

	function closeOpenPanels() {
		$('.nav__section--is-active').click();
		$('.nav__search--is-open').prev('.nav__search-btn').click();
	}
	$('body').click(function() {
		closeOpenPanels();
	});
	$(document).keyup(function(e) {
		if (e.keyCode === 27) closeOpenPanels(); // esc
	});

	/******************************************************************************************
	SCROLL TO ELEMENT
	*******************************************************************************************/

	function scrollToElement(element) {
		// stop nav changing on scroll
		lockNav();
		// get top of our element
		var targetY = element.offset().top;
		// subtract height of nav if it's a sticky one...
		if ($('.site-header').hasClass('site-header--is-fixed')) {
			targetY -= $('.site-header').outerHeight(true);
		}
		$('html, body').animate({
			scrollTop: targetY
		}, 800, function() {
			// re-enable nav
			unlockNav();
		});
	}

	/******************************************************************************************
	ACCORDIONS
	******************************************************************************************/

	$('.js-accordion-heading').click(function() {

		var aHeading = $(this);

		// is accordion enabled at this size?
		var disableFrom = aHeading.get(0).hasAttribute('data-disable-accordion-from') ? aHeading.attr('data-disable-accordion-from') : 999999;
		if ( $(window).width() >= disableFrom ) {
			return;
		}

		aHeading.toggleClass('js-accordion-heading--is-open');
		// tell our nav not to be effected by any scrolling that's about to happen
		lockNav();
		// toggle relevant content
		aHeading.next('.js-accordion-content').slideToggle(400, function() {
			// re-enable nav
			unlockNav();
		});
		// when we open one, close all others
		if (aHeading.is('.js-accordion-heading--is-open')) {
			// calculate where this heading is going to end up so we can scroll to it
			// get current position of heading
			var targetY = aHeading.offset().top;
			// subtract height of any accordion content that's open and above (as page is going to shift up by that much)
			$('.js-accordion-heading--is-open').each(function() {
				if ($(this).offset().top < aHeading.offset().top) {
					targetY -= $(this).next('.js-accordion-content').outerHeight(true);
				}
			});
			// also subtract height of nav if it's a sticky one...
			if ($('.site-header').hasClass('site-header--is-fixed')) {
				targetY -= $('.site-header').outerHeight(true);
			}
			// now close all siblings
			$('.js-accordion-heading--is-open').not(aHeading).removeClass('js-accordion-heading--is-open').next('.js-accordion-content').slideUp();
			// and scroll to our open one
			$('html, body').animate({
				scrollTop: targetY
			}, 400, function() {
				// re-enable nav
				unlockNav();
			});
		}
	});

	/******************************************************************************************
	ANIMATE IN ELEMENTS ON SCROLL
	******************************************************************************************/

	// trigger animations that need triggering

	checkAnimations = function() {
		var delay = 100; // delay before first visible item is triggered
		$('[data-animate]').each(function() {
			// if this element is inside of an owl carousel item and is not currently visible, don't animate it yet
			if ($(this).closest('.owl-item').length && !$(this).closest('.owl-item').hasClass('active')) {
				return;
			}
			// if element is higher on page than the fold (and it's top is not zero, which means owl carousel is working on it), trigger animation
			if ($(window).scrollTop() + $(window).height() > $(this).offset().top && $(this).offset().top != 0) {
				var animatedElement = $(this),
					animationType = animatedElement.attr('data-animate');
				animatedElement.removeAttr('data-animate');
				// then trigger animation after specified delay
				setTimeout(function() {
					// fade in
					if (animationType == 'fade-in') {
						animatedElement.animate({'opacity': 1}, 800);
					// count up
					} else if (animationType == 'count-up') {
						// grab target number from our span we saved earlier
						var span = animatedElement.find('span'),
							targetNumber = span.attr('data-target-number'),
							number = 0;
						// then every fraction of a second...
						var increaseNumber = setInterval(function() {
							// add a 200th of our total
							number = number + (targetNumber / 100);
							// output current number, removing decimal places and adding thousands separator
							span.html(Math.ceil(number).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
							// if we've hit our target, stop calling this function
							if (Math.ceil(number) >= targetNumber) {
								clearInterval(increaseNumber);
								return;
							}
						}, 10);
					// trigger css animation
					} else if (animationType == 'css-animation') {
						animatedElement.addClass('is-animating');
					}
				}, delay);
				// increase our delay by the amount specified on this element
				delay += parseInt(animatedElement.attr('data-animate-delay') ? animatedElement.attr('data-animate-delay') : 300);
			}
		});
	};

	// prep animations

	if ($('[data-animate]').length) {
		// prep animations
		$('[data-animate]').each(function() {
			var animationType = $(this).attr('data-animate');
			if (animationType == 'fade-in') {
				// don't fade in on mobile
				if (screen_size == 'small') {
					$(this).removeAttr('data-animate');
					return;
				}
				$(this).css('opacity', '0');
			} else if (animationType == 'count-up') {
				var html = $(this).html().trim(),
					number = html.match(/[\d,.]+/g);
				// if this doesn't contain a recognisable number, do nothing
				if (!number) {
					$(this).removeAttr('data-animate');
				} else {
					var htmlChunks = html.split(number[0]);
					// turn result of regex into actual number
					number = parseInt(number[0].replace(',', ''));
					$(this).html(htmlChunks[0] + '<span data-target-number="' + number + '">0</span>' + htmlChunks[1]);
				}
			}
		});
		// check if animations need triggering whenever page is loaded or scrolled
		$(window).on('scroll resize', _.throttle(function() {
			checkAnimations();
		}, 100));
		// also check if they need triggering a bit after page loads, as things might have moved around a bit and made something visible
		setTimeout(checkAnimations, 400);
	}

	/******************************************************************************************
	OWL CAROUSELS
	*******************************************************************************************/

	$('.owl-carousel').each(function() {
		// onRefresh, onRefreshed stuff below creates equal height owl items (based on https://github.com/OwlCarousel2/OwlCarousel2/issues/378). Set child items to 100% height to get equal heights!
		var owl = $(this),
			owlOptions = {
				dots: true,
				loop: true,
				items: 1,
				onTranslated: function() {
					checkAnimations();
				}
			},
			disableFrom = owl.get(0).hasAttribute('data-disable-owl-from') ? owl.attr('data-disable-owl-from') : null;

		// if we're showing this owl at all sizes, it's simple!
		if (!disableFrom) {
			owl.owlCarousel(owlOptions);
			return;
		}

		// otherwise we need to enable/disable it at the breakpoint specified on our html tag
		// based on http://stackoverflow.com/questions/28251644/disabling-owl-carousel-at-a-specific-viewport-width
		if ( $(window).width() < disableFrom ) {
			owl.owlCarousel(owlOptions);
		} else {
			owl.addClass('off');
		}
		$(window).resize(function() {
			if ( $(window).width() < disableFrom ) {
				if ( owl.hasClass('off') ) {
					owl.owlCarousel(owlOptions);
					owl.removeClass('off');
				}
			} else {
				if ( !owl.hasClass('off') ) {
					owl.addClass('off').trigger('destroy.owl.carousel');
					owl.find('.owl-stage-outer').children(':eq(0)').unwrap();
				}
			}
		});
	});

	/******************************************************************************************
	SHARE LINKS
	*******************************************************************************************/

	if ($('.share').length) {
		$('.share li:not(\'.em\') a').click(function() {
			window.open($(this).attr('href'), 'share', 'height=380,width=600,toolbar=0,location=0,menubar=0');
			return false;
		});
	}

	/******************************************************************************************
	AJAX LOAD
	*******************************************************************************************/

	$('[data-ajax-target]').each(function() {
		var link = $(this),
			target = $($(this).attr('data-ajax-target')),
			grab = $(this).attr('data-ajax-grab'),
			totalPages = $(this).attr('data-ajax-total-pages'),
			currentPage = 1,
			buttonWrap = $($(this).attr('data-ajax-button-wrap')),
			linkLoadingClass = 'is-loading';

		link.click(function(e) {
			e.preventDefault();

			if (link.hasClass(linkLoadingClass)) return;

			link.addClass(linkLoadingClass);

			$.get(link.attr('href'),function(data) {
				var content = $(data).find(grab);
				target.append(content);

				// update href of link to point to next page
				currentPage++;
				var newLinkUrl = link.attr('href').replace(/\/p[0-9]+/, '/p' + (currentPage + 1));
				console.log(currentPage);
				link.attr('href', newLinkUrl);
				// if we're on last page, hide our "load more" button
				if (currentPage >= totalPages) {
					buttonWrap.hide();
				}

				link.removeClass(linkLoadingClass);

			});
		});
	});

	/******************************************************************************************
	DONATIONS
	******************************************************************************************/

	// first page

	function updateDonatePrompt() {
		$('.donate-form__radio-group__prompt').hide();
		$('.donate-form__radio-group__prompt[data-for=' + $('.donate-form__radio-group--planAmount input:checked').attr('id') +']').show();
	}

	if ($('.donate-form__form').length) {

		// when switching between monthly and single...
		$('.donate-form__radio-group__radio--planInterval').change(function() {
			// hide all plan amounts
			$('.donate-form__radio-group--planAmount').hide();
			var planInterval = $('.donate-form__radio-group__radio--planInterval:checked').val();
			// show the relevant plan amounts, and select first one
			$('.donate-form__radio-group--' + planInterval).show().find('input[type=radio]').first().prop('checked', true);
			updateDonatePrompt();
		});

		// when switching between amounts, show relevant prompt text
		var radioButtons = $('.donate-form__radio-group--planAmount input');

		radioButtons.change(function() {
			updateDonatePrompt();
		});
	}

	// second page

	var phoneCheckboxes = $('.js-communication-pref-requires-phone'),
		target = $('.js-donate-telephone');

	phoneCheckboxes.change(function() {
		if ($('.js-communication-pref-requires-phone:checked').length) {
			target.slideDown();
			target.find('input').attr('required', 'required');
		} else {
			target.slideUp();
			target.find('input').removeAttr('required');
		}
	});

	/******************************************************************************************
	MINI CONTENT TOGGLES
	******************************************************************************************/

	$('.mini-content-toggle').click(function() {
		$(this).toggleClass('mini-content-toggle--active');
		$($(this).attr('data-target')).slideToggle();
	});

	/******************************************************************************************
	NEWSLETTER
	******************************************************************************************/

	$('.follow__subheading--newsletter').click(function() {
		$(this).toggleClass('follow__subheading--is-active');
		$('.follow__newsletter-form').slideToggle();
	});

	/******************************************************************************************
	FORM VALIDATION
	******************************************************************************************/

	if ($('.js-validate').length) {
		var form = $('.js-validate'),
			requiredFields = form.find('input, textarea, select').filter('[required]'),
			foundErrors = false;

		// on submit, check if any required fields are empty and add errors if so. otherwise just submit form

		form.submit(function(e) {
			e.preventDefault();

			// remove any previous errors
			$('.errors').remove();
			$('.form__field').removeClass('has-error');

			// check each required field
			requiredFields.each(function() {
				if ($(this).val() == '') {
					$(this).before('<ul class="errors help-block"><li>This field is required</li></ul>');
					$(this).closest('.form__field').addClass('has-error');
					foundErrors = true;
				}
			});

			if (foundErrors) {
				scrollToElement($('.form__field.has-error').first());
			}
		});

		// when a required field changes, remove error message if it had one

		requiredFields.change(function() {
			if ($(this).val != '' && $(this).closest('.form__field').hasClass('has-error')) {
				$(this).closest('.form__field').removeClass('has-error');
				$(this).prev('.errors').remove();
			}
		});
	}

	/******************************************************************************************
	Filter Toggle
	*******************************************************************************************/
	$('.filter-button').on('click', function(event) {
		$(this).toggleClass('active');
		$(this).siblings('.filter-row').slideToggle();
	});

	/******************************************************************************************
	SMOOTH SCROLL (to all # links)
	*******************************************************************************************/

	$('a[href*="#"]:not([href="#"])').click(function() {
		if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
			var targetElement = $(this.hash);
			targetElement = targetElement.length ? targetElement : $('[name=' + this.hash.slice(1) +']');
			scrollToElement($(targetElement));
		}
	});

	/******************************************************************************************
	COOKIE POPUP
	*******************************************************************************************/

	var cookieConsent = localStorage.getItem('cookieConsent');

	if (cookieConsent == null) {
		$('.cookie-popup').slideDown(300);
	}

	$('#cookie-button').click(function (e) {
		e.preventDefault();
		$('.cookie-popup').slideUp(300);

		cookieConsent = true;
		localStorage.setItem('cookieConsent', cookieConsent);
	});

});
